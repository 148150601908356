<template>
  <div>
    <b-card no-body class="shadow">
      <PaginatorTable :is-busy="sites === null" :hide-filter="sites.length < 10" :hide-top-pagination="sites.length < 25"
                      :fields="site_list_fields" :show_default_no_items_warning="false"
                      :items="sites" :sort-by="sort_by">
        <template v-slot:cell(fq_name)="data">
          <b-link class="nowrap" :to="'/netdoc/sites/'+data.value"><b>{{ data.value }}</b></b-link>
        </template>
        <template v-slot:cell(type)="data">
          {{ $t_netdb(site_types[data.item.type].description) }}
        </template>
      </PaginatorTable>
      <div v-if="sites && sites.length === 0" class="font-italic text-center mb-3">
        {{ $t('components.bcd_list.no_sites_available') }}
      </div>
      <b-pagination v-if="sites && sites.length > per_page && filter === ''" :total-rows="sites.length"
                    :per-page="per_page" v-model="current_page" align="center"></b-pagination>
    </b-card>
  </div>
</template>

<script>
import PaginatorTable from '@/components/PaginatorTable.vue'

export default {
  name: 'SiteList',
  components: { PaginatorTable },
  watch: {
    filter: {
      immediate: true,
      async handler() {
        if (this.filter_callback !== null) {
          const result = await this.filter_callback(this.filter)
          if (result !== null) {
            this.filter = result
          }
        }
      }
    },
  },
  data() {
    return {
      filter: '',
      current_page: 1,
      per_page: 100,
      sort_by: null,
    }
  },
  computed: {
    site_list_fields() {
      return [
        {
          label: this.$t('system.name'),
          key: 'fq_name',
          sortable: true
        },
        {
          label: this.$t('system.description'),
          key: 'description',
          sortable: true
        },
        {
          label: this.$t('views.nd.sites.type'),
          key: 'type',
          sortable: true
        },
      ]
    }
  },
  props: {
    default_sort_by: {
      required: false,
      default() {
        return null
      }
    },
    sites: {
      required: true,
      default() {
        return null
      }
    },
    site_types: {
      required: true,
      default() {
        return null
      }
    },
    filter_callback: {
      required: false,
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>
</style>
