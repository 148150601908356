<template>
  <div id="SitesOverview">
    <h1>{{ $tc('views.nd.netdoc.sites', 2) }}</h1>
    <LoadingPlaceholder :data="sites">
      <SiteList :sites="sites" :site_types="site_types"></SiteList>
    </LoadingPlaceholder>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/components/Loading.vue'
import SiteList from '@/components/nd/SiteList.vue'
import netdocService from '@/api-services/netdoc.service'
import apiutil from '@/util/apiutil'

export default {
  name: 'NetdocSiteOverview',
  components: { SiteList, LoadingPlaceholder },
  data() {
    return {
      sites: null,
      site_types: null,
    }
  },
  async created() {
    // const response = await NdSite.list(this.$store.state, {})
    const response = await netdocService.getSites(this.$store.state)
    this.sites = response.data.site_list
    this.site_types = apiutil.dict_by_value_of_array(response.data.site_type_list, 'short_name')
  }
}
</script>

<style scoped>

</style>
